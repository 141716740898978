import { Controller } from "stimulus"

export default class extends Controller {
    // static targets = [ "source" ]

    connect() {
        var clipboard = new ClipboardJS('.clipboard-value');
        clipboard.on('success', function(e) {
            e.trigger.dataset.originalTitle = 'Copied!';
            $(e.trigger).tooltip('show');
            e.trigger.dataset.originalTitle = 'Click to copy';
            e.clearSelection();
        });
    }

/*
    copy(event) {
        event.preventDefault();
        if (window.getSelection) {
            var range = document.createRange();
            window.getSelection().removeAllRanges();
            range.selectNode(this.sourceTarget);
            var selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand('copy');
        }
    }
*/
}
