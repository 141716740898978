import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
    static targets = [ 'term', 'hierarchies', 'toggler' ]

    toggleHierarchies(event) {
        event.preventDefault();

        if (this.togglerTarget.innerText == '⊕') {
            Rails.ajax({
                url: "/hierarchies.html",
                type: "get",
                data: `term_id=${encodeURIComponent(this.termTarget.textContent)}`,
                success: (function(data) {
                    let hierarchiesHTML = data.querySelector('.hierarchies').innerHTML
                    this.hierarchiesTarget.innerHTML = hierarchiesHTML;
                }).bind(this),
                error: function(data) {}
            })
            this.togglerTarget.innerText = '⊗';
        } else {
            this.hierarchiesTarget.innerHTML = '';
            this.togglerTarget.innerText = '⊕';
        }
    }

}