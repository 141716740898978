import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["organizationName", "organizationButton","personalButton"]

    connect() {
        this.toggleOrganizationName()
    }

    toggleOrganizationName(event) {
        if (this.organizationButtonTarget.checked) {
            this.organizationNameTarget.disabled = false
        } else if (this.personalButtonTarget.checked) {
            this.organizationNameTarget.disabled = true
            this.organizationNameTarget.value = ''
        }
    }
}
